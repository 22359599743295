<template>
  <div>
    <el-row :gutter="10"
            v-if="form">
      <el-button type="primary"
                 @click="$router.go(-1)">返回</el-button>
      <el-col>
        <span>考点名：</span>{{tableData.knowledge_data.knowledge_desc}}
      </el-col>
    </el-row>
    <el-table border
              stripe
              highlight-current-row
              :max-height="$store.state.tableHeight"
              :data="tableData.user_data.list"
              style="width: 100%">
      <el-table-column prop="id"
                       align="center"
                       width="100"
                       label="学生ID" />
      <el-table-column prop="truename"
                       align="center"
                       label="学生姓名" />
      <el-table-column prop="count"
                       align="center"
                       label="学生做题次数" />
      <el-table-column prop="score"
                       align="center"
                       label="学生得分率" />
      <el-table-column align="center"
                       v-if="flag"
                       label="操作">
        <template slot-scope="{row}">
          <el-button type="primary"
                     @click="buildPaper(row)">
            考点组卷
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Form ref="FormRef" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { getNowYear } from '@/utils/date.js'
import Form from './form.vue'
export default {
  components: {
    Form
  },
  data () {
    return {
      tableData: {
        knowledge_data: {
          knowledge_desc: '',
        },
        user_data: {
          list: []
        },

      },
      form: null,
      flag: false
    }
  },
  computed: {

    ...mapState('menu/', {
      'menus': 'menuList'
    })
  },
  mounted () {
    console.log('ss', this.menus);
    this.menus.map((item) => {
      if (item.menu_name == '组卷') {
        this.flag = true
      }
    })

    this.initData()
  },
  methods: {
    initData () {
      this.form = JSON.parse(this.$route.query.form)
      this.$http({
        url: '/api/v1/combination/user_knowldge',
        method: 'get',
        params: this.form
      }).then(res => {
        this.tableData = res.data
      })
    },
    buildPaper (row) {
      let form = {
        name: '',
        district_id: '',
        subject_id: this.form.subject_id,
        source_id: '',
        type_id: [],
        category_id: '',
        begin_year: `${getNowYear() - 3}`,
        end_year: getNowYear(),
        paper_name: '',
        questionNumber: '10',
        select_all: '',
        paper_time: '',
        semester: 2,
        is_trial: 0,
        edu_paper_type: '',
        grade_id: 1,
        paper_duration: '',
        question_difficulty: '',
        end_time: '',
        user_ids: [row.id],
        isBrackets: true
      }
      this.$refs.FormRef.form = form
      this.$refs.FormRef.getDistrictList()
      this.$refs.FormRef.getSubjectList()
      this.$refs.FormRef.checkChange()

      setTimeout(() => {
        this.$refs.FormRef.getSourceList()
      }, 1000);
      this.$refs.FormRef.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.el-col {
  font-size: 16px;
  padding: 10px;
  span {
    font-weight: bold;
  }
}
</style>
